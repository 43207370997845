import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h2: "h2",
    h3: "h3",
    li: "li",
    ol: "ol",
    p: "p",
    pre: "pre",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "Wallet handles any and all ui & logic for purchasing, keeping track of those purchases, and canceling subscriptions."
    }), "\n", _jsx(_components.h3, {
      id: "features",
      children: _jsx(_components.a, {
        href: "#features",
        children: "Features"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "A route with a portfolio of current (pro) subscriptions, payment methods, and a way to add more."
      }), "\n", _jsx(_components.li, {
        children: "A route with all transactions a user has made with Blitz"
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "critical-functions",
      children: _jsx(_components.a, {
        href: "#critical-functions",
        children: "Critical Functions"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Credit card integration with Stripe"
      }), "\n", _jsx(_components.li, {
        children: "Deleting payment methods"
      }), "\n", _jsx(_components.li, {
        children: "Transaction receipt print/download"
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "wallet-docs",
      children: _jsx(_components.a, {
        href: "#wallet-docs",
        children: "Wallet docs:"
      })
    }), "\n", _jsx(_components.p, {
      children: "There are separate staging servers for payments. This currently includes a payments & auth server. Currently, the payments server is the only normally enabled staging server in test because the staging auth server does not work with other prod services. To test payments locally:"
    }), "\n", _jsxs(_components.ol, {
      children: ["\n", _jsxs(_components.li, {
        children: ["remove ", _jsx(_components.code, {
          children: "payments-test-"
        }), " from ", _jsx(_components.code, {
          children: "AUTH_GRAPHQL"
        }), " & ", _jsx(_components.code, {
          children: "AUTH_WS_URL"
        }), " in ", _jsx(_components.code, {
          children: "constants-dev-urls.json"
        })]
      }), "\n", _jsxs(_components.li, {
        children: ["sign out of your current user & create a new one.", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsx(_components.li, {
            children: "this can use the same email b/c of the staging service"
          }), "\n", _jsxs(_components.li, {
            children: ["or you can use a ", _jsx(_components.code, {
              children: "+1"
            }), " at the end of your email to create a new account (", _jsx(_components.a, {
              href: "mailto:kancell@blitz.gg",
              children: "kancell@blitz.gg"
            }), " -> ", _jsx(_components.a, {
              href: "mailto:kancell+5@blitz.gg",
              children: "kancell+5@blitz.gg"
            }), ")"]
          }), "\n"]
        }), "\n"]
      }), "\n", _jsx(_components.li, {
        children: "test away locally"
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "creating-a-build",
      children: _jsx(_components.a, {
        href: "#creating-a-build",
        children: "Creating a build"
      })
    }), "\n", _jsxs(_components.ol, {
      children: ["\n", _jsxs(_components.li, {
        children: ["in feature-wallet/constants.mts hard code ", _jsx(_components.code, {
          children: "STRIPE_PUBLISHABLE_KEY"
        }), " to ", _jsx(_components.code, {
          children: "pk_test_..."
        })]
      }), "\n", _jsxs(_components.li, {
        children: ["hardcode ", _jsx(_components.code, {
          children: "constants-app-urls.json"
        }), " to use the staging payments & both auth services"]
      }), "\n", _jsxs(_components.li, {
        children: ["in ", _jsx(_components.code, {
          children: "get-data.mts"
        }), " comment out the line with ", _jsx(_components.code, {
          children: "mainRefs.checkForAuthorization"
        }), " or it will sign you out constantly"]
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "card-testing",
      children: _jsx(_components.a, {
        href: "#card-testing",
        children: "Card testing"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: _jsx(_components.a, {
          href: "https://stripe.com/docs/testing#cards",
          children: "https://stripe.com/docs/testing#cards"
        })
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "test-clocks",
      children: _jsx(_components.a, {
        href: "#test-clocks",
        children: "Test Clocks"
      })
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.code, {
        children: "__BLITZ_DEV__.wallet"
      }), " has a few dev helpers accessible in the console. Test clocks can only be attached to a ", _jsx(_components.strong, {
        children: "NEW USER"
      }), ". Use the instructions above to create hassle free new user."]
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Create a test clock for the current user w/:"
      }), "\n"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "await __BLITZ_DEV__.wallet.createTestClock(); // accepts a Date for start time\n__BLITZ_DEV__.wallet.testClock;\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "or"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Fetch the existing test clock for the user w/"
      }), "\n"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "await __BLITZ_DEV__.wallet.fetchUserTestClock();\n__BLITZ_DEV__.wallet.testClock;\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "or"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Manually set the test clock ", _jsx(_components.a, {
          href: "https://dashboard.stripe.com/test/test-clocks",
          children: "to an existing one"
        }), " w/"]
      }), "\n"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "__BLITZ_DEV__.wallet.testClock = {\n  id: \"clock_1NnTApEE4xt84LKu0hej0SHE\",\n  frozen_time: 1694035325,\n};\n"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Sign up for pro or create a new payment method. It will automatically include the test clock in memory."
      }), "\n", _jsx(_components.li, {
        children: "Test w/ the clock:"
      }), "\n"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "await __BLITZ_DEV__.wallet.advanceClock(); // accepts a Date param of when to advance to or leave empty for Date.now()\nawait __BLITZ_DEV__.wallet.advanceClockByDay(); // accepts a number\nawait __BLITZ_DEV__.wallet.advanceClockByMonth(); // accepts a number\n"
      })
    }), "\n", _jsx(_components.h3, {
      id: "todos",
      children: _jsx(_components.a, {
        href: "#todos",
        children: "TODO's"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "html2canvas seems to be having issues rendering text on new browser versions. It is disabled for now."
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
